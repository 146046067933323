<template>
  <div>
    <h2>文件列表</h2>
    <el-table
        :data="fileList"
        style="width: 100%">
      <el-table-column prop="name" label="文件名">
        <template slot-scope="scope">
          <a :href="scope.row.path" download>{{ scope.row.name }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="type" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="copyToClipboard(scope.row.path)">复制地址</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        title="文件详情"
        :visible.sync="showDetailsDialog"
        width="80%">
      <el-row>
        <el-col :span="8">文件名:</el-col>
        <el-col :span="16">{{ selectedFile.name }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">类型:</el-col>
        <el-col :span="16">{{ selectedFile.type }}</el-col>
      </el-row>
      <!-- 其他文件属性 -->
    </el-dialog>
  </div>
</template>

<script>
import ExpandIndex from "../../request/expand/index";

export default {
  data() {
    return {
      ask: new ExpandIndex(),
      fileList: [],
      showDetailsDialog: false,
      selectedFile: {}
    };
  },
  methods: {
    copyToClipboard(path) {
      let textToCopy = window.location.origin + path
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        this.$message({
          showClose: true,
          message: '无法复制文本到剪贴板',
          type: 'error'
        });
      }

      document.body.removeChild(textArea);
    },
    showFileDetails(row) {
      this.selectedFile = row;
      this.showDetailsDialog = true;
    },
    /**
     * 截取文件类型
     * @param fileName
     */
    interceptFileType(fileName) {
      let indexI = fileName.lastIndexOf(".");
      if (indexI === -1) {
        return "?";
      } else {
        return fileName.substring(indexI, fileName.length)
      }
    }
  },
  mounted() {
    let path = this.$route.query.to;
    let this_ = this;
    this.ask.directoryFile({
      "mod": "file_kc",
      "path": path,
      "isFileName": true,
      "isContainsChildren": false
    }).then(res => {
      if (res.code === 'success') {
        res.msg.forEach(item => {
          this_.fileList.push(
              {
                "name": item,
                "type": this_.interceptFileType(item),
                "path": "/api/resources/file_kc/" + path + "/" + item
              }
          )
        })
      }
    })
  }
};
</script>
